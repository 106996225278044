import { Config } from '../../interfaces/config.interface';
import Logo from './logo.jpeg';

const config: Config = {
  name: 'Nodo',
  title: 'Actividades de voluntariado',
  logo: Logo,
};

export default config;
